import React from 'react';
import { Icon } from '../Icon/Icon';
import topbarStyles from './Topbar.module.css';
import emailIcon from '../../assets/img/icons/email.svg';
import locationPinIcon from '../../assets/img/icons/location-pin.svg';
import phoneIcon from '../../assets/img/icons/phone.svg';
import { LanguageDropdown } from '../LanguageDropdown/LanguageDropdown';

export function Topbar() {
  return (
    <div className={topbarStyles.topbar}>
      <Icon.InlineGroup>
        <Icon img={phoneIcon} label='+54 9 11 3032 8493' classes={topbarStyles.item} />
        <Icon img={emailIcon} label='info@lalliance.com.ar' classes={topbarStyles.item} />
        <Icon img={locationPinIcon} label='Av. Mitre 2900, Sarandí, Buenos Aires, AR' classes={topbarStyles.item} />
      </Icon.InlineGroup>
      <LanguageDropdown />
    </div>
  );
}
