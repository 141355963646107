import { CustomerLogin } from './CustomerLogin/CustomerLogin';
import { GetPricing } from './GetPricing/GetPricing';
import { ReachOut } from './ReactOut/ReachOut';
import { Social } from './Social/Social';
import { SubmitForm } from './SubmitForm/SubmitForm';

export class Links {
  static GetPricing = GetPricing;
  static CustomerLogin = CustomerLogin;
  static Social = Social;
  static ReachOut = ReachOut;
  static SubmitForm = SubmitForm;
}
