import React from 'react';
import { Links } from '../Links/Links';
import { Logo } from '../Logo/Logo';
import footerStyles from './Footer.module.css';

export function Footer() {
  return (
    <footer className={footerStyles.footer}>
      <Logo logoStyle='white' />
      <div className={footerStyles['footer__extra-links']}>
        <Links.Social></Links.Social>
        <p>{new Date().getFullYear()} L'alliance SA. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
}
