import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import getPricingStyles from './GetPricing.module.css';

export function GetPricing() {
  const { t } = useTranslation();
  return (
    <button className={getPricingStyles.button}>
      <Link to='#contact'>{t('buttons:pricing')}</Link>
    </button>
  );
}
