import React from 'react';
import { Icon } from '../../Icon/Icon';
import tabContentHeaderStyles from './TabContentHeader.module.css';

export function TabContentHeader(props) {
  return (
    <div className={tabContentHeaderStyles['tab-content-header']}>
      <Icon
        img={props.icon}
        classes={tabContentHeaderStyles['tab-content-header__icon']}
        size='30px'
        label={props.label}
      />
    </div>
  );
}
