import React from 'react';
import socialStyles from './Social.module.css';

import emailIcon from '../../../assets/img/icons/social/email.svg';
import facebookIcon from '../../../assets/img/icons/social/facebook.svg';
import instagramIcon from '../../../assets/img/icons/social/instagram.svg';
import whatsappIcon from '../../../assets/img/icons/social/whatsapp.svg';

export function Social(props) {
  return (
    <div className={socialStyles['social-links']}>
      <a href='#'>
        <img src={facebookIcon} alt='' />
      </a>
      <a href='#'>
        <img src={instagramIcon} alt='' />
      </a>
      <a href='#'>
        <img src={whatsappIcon} alt='' />
      </a>
      <a href='#'>
        <img src={emailIcon} alt='' />
      </a>
    </div>
  );
}
