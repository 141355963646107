import React from 'react';
import { useTranslation } from 'react-i18next';
import sectionHeaderStyles from './SectionHeader.module.css';

export function SectionHeader(props) {
  const { t } = useTranslation();
  return (
    <header className={sectionHeaderStyles.header}>
      <h3>{t(props.title)}</h3>
      <p>{t(props.subtitle)}</p>
    </header>
  );
}
