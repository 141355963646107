import React from 'react';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { useTranslation } from 'react-i18next';
import { useEffect, useState} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from 'dayjs';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { Product } from '../../Product/Product';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useCategories, useContact, useRenting, useStores } from '@wb/react-hooks';
import styles from './RentACar.module.css';
import { Grid, TextField } from '@material-ui/core';

export function RentACar() {
  const [t] = useTranslation();
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isSameLocation, setIsSameLocation] = useState(true);
  const [rentingDetails, setRentingDetails] = useState(null);
  const [rentingIsComplete, setRentingIsComplete] = useState(null);
  const { create: sendEmail } = useContact('65ee7178b0324631760ae54a');
  const { list: listStores } = useStores();
  const { list: listCategories } = useCategories();
  const { rent, listAvailable } = useRenting();
  const [form, setForm] = useState({ product: null, dropOffStore: null, pickUpStore: null, category: null, startsAt: null, endsAt: null, name: null, email: null })
  
  useEffect(() => {
    listCategories().then(({ data }) => {
      setCategories(data);
    })
    listStores().then(({ data }) => {
      setStores(data);
    })
  }, []);

  const isFormValid = () => {
    return form.pickUpStore && form.startsAt && form.endsAt;
  }

  const getAvailability = () => {
    if(!form.pickUpStore || !form.startsAt || !form.endsAt) return;
    setProducts([]);
    listAvailable(
      form.pickUpStore,
      {
        pickUpStore: form.pickUpStore,
        dropOffStore: isSameLocation ? null : form.dropOffStore,
        startsAt: (form.startsAt).toISOString(),
        endsAt: (form.endsAt).toISOString(),
        ...(form.category ? { categories: [form.category] } : {})
      }
    ).then(({ data }) => {
      setProducts(data.products);
    })
  }

  const handleBooking = ({ product, variants }) => {
    setRentingIsComplete({ product, variants });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    rent(
      form.pickUpStore,
      {
        startsAt: (form.startsAt).toISOString(),
        endsAt: (form.endsAt).toISOString(),
        products: [rentingIsComplete],
        customer: { name: form.name, email: form.email }
      }
    ).then(({ data }) => {
      setRentingDetails(data);
      sendEmail({
        name: form.name,
        email: form.email,
        'Id de la reserva': data._id,
        modelo: data.products[0].name.es,
        desde: dayjs(data.startsAt).format('DD/MM/YYYY'),
        hasta: dayjs(data.endsAt).format('DD/MM/YYYY'),
        monto: data.amount
      });
    })
  }

  const changeDates = (key) => (change) => {
    setProducts([]);
    setForm((form) => ({ ...form, [key]: change }))
  }

  const updateForm = (key) => (change) => {
    setProducts([]);
    setForm((form) => ({ ...form, [key]: (change?.target ?? change?.currentTarget).value }))
  };

  return (
    <section className={styles.section} id="rentacar">
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <SectionHeader title='section:rentacar:title' subtitle='section:rentacar:subtitle'></SectionHeader>
        <div style={{ padding: '5rem 0' }}>
          { rentingIsComplete ? <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  placeholder='Nombre y Apellido'
                  label='Nombre y Apellido'
                  variant='outlined'
                  fullWidth
                  required
                  value={form.name}
                  onChange={updateForm('name')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type='email'
                  placeholder='Email'
                  label='Correo electrónico'
                  variant='outlined'
                  fullWidth
                  required
                  value={form.email}
                  onChange={updateForm('email')}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant='contained' color='primary' type="submit">{t('booking:form:confirm-booking')}</Button>
              </Grid>
            </Grid>
          </form> : 
            rentingDetails ? 
              <Container maxWidth='md' style={{ margin: '0 auto', display: 'flex', gap: '1rem', flexDirection: 'column', justifyContent: 'center' }}>
                <CheckCircleOutlineIcon style={{ color: 'green' }} />
                <Typography variant='h5'>{t('booking:confirmed')}</Typography>
                <Typography
                  variant='body1'
                  dangerouslySetInnerHTML={{ __html: t('booking:details', { bookingId: rentingDetails._id }) }}
                ></Typography>
                <Typography variant='body1'>
                  <span dangerouslySetInnerHTML={{ __html: t(
                      'booking:summary',
                      {
                        name: rentingDetails.products[0].name.es,
                        startsAt: dayjs(rentingDetails.startsAt).format('DD/MM/YYYY'),
                        endsAt: dayjs(rentingDetails.endsAt).format('DD/MM/YYYY'),
                        amount: rentingDetails.amount
                      }
                    )
                  }}></span>
                </Typography>
                <Button onClick={() => {
                  setRentingDetails(null);
                  setForm({ product: null, dropOffStore: null, pickUpStore: null, category: null, startsAt: null, endsAt: null, name: null, email: null })
                  setProducts([])
                  setRentingIsComplete(null);
                }}>{t('booking:new')}</Button>
              </Container>
              : 
              <Container maxWidth='md' style={{ margin: '0 auto', display: 'flex', gap: '1rem', flexDirection: 'column', justifyContent: 'center' }}>
                <Stack direction={'row'} sx={{ gap: '1rem' }}>
                  <FormControl sx={{ width: '100%', flexGrow: 1 }}>
                    <InputLabel id="category">{t('booking:form:kind')}</InputLabel>
                    <Select
                      labelId="category"
                      id="category"
                      value={form.category}
                      label={t('booking:form:kind')}
                      onChange={updateForm('category')}
                      sx={{ backgroundColor: 'white' }}
                    >
                      {categories.map(({ _id: categoryId, name: { es: name } }) => {
                        return <MenuItem value={categoryId}>{name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <Stack direction={'column'} gap={'.25rem'} sx={{ width: '100%', flexGrow: 1 }}>
                    <Stack direction={'row'} gap={'1rem'}>
                      <FormControl fullWidth>
                        <InputLabel id="store">{t('booking:form:pickUp')}</InputLabel>
                        <Select
                          labelId="store"
                          id="store"
                          value={form.pickUpStore}
                          label={t('booking:form:pickUp')}
                          onChange={updateForm('pickUpStore')}
                          sx={{ backgroundColor: 'white' }}
                        >
                          {stores.map(({ _id: storeId, name: { es: name } }) => {
                            return <MenuItem value={storeId}>{name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      {!isSameLocation && 
                        <FormControl fullWidth>
                          <InputLabel id="store">{t('booking:form:dropOff')}</InputLabel>
                          <Select
                            labelId="store"
                            id="store"
                            value={form.dropOffStore}
                            label={t('booking:form:dropOff')}
                            onChange={updateForm('dropOffStore')}
                            sx={{ backgroundColor: 'white' }}
                          >
                            {stores.map(({ _id: storeId, name: { es: name } }) => {
                              return <MenuItem value={storeId}>{name}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      }
                    </Stack>
                    <FormHelperText>
                      <FormControlLabel
                        control={<Checkbox onChange={(evt) => setIsSameLocation(evt.target.checked)} defaultChecked={isSameLocation} />}
                        label={t('booking:form:sameLocation')}
                      />
                    </FormHelperText>
                  </Stack>
                </Stack>
                <Stack direction={'row'} gap={'1rem'} rowGap={'1rem'}>
                  <DatePicker
                    label={t('booking:form:from')}
                    value={form.startsAt}
                    onChange={changeDates('startsAt')}
                    sx={{ width: "100%", backgroundColor: 'white' }}
                    disablePast
                  />
                  <DatePicker
                    label={t('booking:form:to')}
                    minDate={form.startsAt}
                    value={form.endsAt}
                    onChange={changeDates('endsAt')}
                    sx={{ width: "100%", backgroundColor: 'white' }}
                    disablePast
                  />
                </Stack>

                <Container sx={{ display: 'flex', justifyContent: 'flex-end', margin: 0, padding: '0 !important' }}>
                  <Button variant='contained' color='primary' disabled={!isFormValid()} onClick={getAvailability}>{t('booking:form:submit')}</Button>
                </Container>
                  {
                    products.map((product) => <Product rentLengthInDays={form.endsAt?.diff(form.startsAt, 'day') ?? 0} product={product} onSelectProduct={handleBooking} />)
                  }
              </Container>
          }
        </div>
      </div>
    </section>
  );
}
