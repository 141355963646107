import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import classnames from 'classnames';
import menuStyles from './Menu.module.css';
import { useTranslation } from 'react-i18next';

export function Menu(props) {
  const { t } = useTranslation();
  return (
    <nav className={classnames({ [menuStyles['menu--dark']]: props.useBlack })}>
      <Link smooth to='#root'>
        {t('menu:links:home')}
      </Link>
      <Link smooth to='#contact'>
       {t('menu:links:rentacar')}
      </Link>
      <Link smooth to='#renting'>
       {t('menu:links:renting')}
      </Link>
      <Link smooth to='#empresa'>
       {t('menu:links:company')}
      </Link>
      <Link smooth to='#flota'>
       {t('menu:links:fleet')}
      </Link>
    </nav>
  );
}
