import React, { useState } from 'react';
import { useScrollPosition } from '../../hooks/useScrollPosition.hook';
import { Links } from '../Links/Links';
import { Logo } from '../Logo/Logo';
import { Menu } from '../Menu/Menu';
import navbarStyles from './NavBar.module.css';
import classnames from 'classnames';
import { LanguageDropdown } from '../LanguageDropdown/LanguageDropdown';

const DEFAULT_DISTANCE = 600;

export function NavBar(props) {
  const isFixed = 'fixed' in props;
  const showFrom = isFixed && props.showFrom ? props.showFrom ?? DEFAULT_DISTANCE : 0;
  const [showing, setShowing] = useState(props.initialState ?? false);

  const onScroll = ({ currPos }) => {
    if (isFixed) {
      if (currPos.y > showFrom) {
        setShowing(true);
      } else if (currPos.y < showFrom) {
        setShowing(false);
      }
    }
  };

  useScrollPosition(onScroll, [], null, true);

  return (
    <header
      className={classnames(navbarStyles.navbar, {
        [navbarStyles['navbar__fixed']]: isFixed,
        [navbarStyles['navbar__fixed--showing']]: showing,
      })}
      id="top"
    >
      <Logo height='70px' logoStyle={isFixed ? 'black' : 'white'} />
      <Menu useBlack={isFixed} />
      <div className={navbarStyles['navbar__special']}>
        {/* <Links.CustomerLogin /> */}
        {/* <Links.GetPricing /> */}
        <span className={navbarStyles['navbar__special--language']}><LanguageDropdown /></span>
        <Links.ReachOut />
      </div>

    </header>
  );
}
