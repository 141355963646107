import React from 'react';
import { Icon } from '../Icon/Icon';
import classnames from 'classnames';
import selectableItemStyles from './SelectableItem.module.css';

export function SelectableItem(props) {
  return (
    <div
      className={classnames(selectableItemStyles['selectable-item'], {
        [selectableItemStyles['selectable-item--selected']]: props.selected,
      })}
    >
      <Icon
        img={props.selected ? props.onSelectedIcon : props.icon}
        classes={selectableItemStyles['selectable-item__icon']}
        label={props.label}
      />
    </div>
  );
}
