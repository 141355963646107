import React from 'react';
import { useTranslation } from 'react-i18next';
import submitFormStyles from './SubmitForm.module.css';

export function SubmitForm({ disabled }) {
  const { t } = useTranslation();
  return (
    <button type="submit" className={submitFormStyles.button} disabled={disabled}>
      <span>{t('buttons:submit')}</span>
    </button>
  );
}
