import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../Dropdown/Dropdown';
import { Icon } from '../Icon/Icon';
import USFlag from '../../assets/img/icons/USFlag.svg';
import ARFlag from '../../assets/img/icons/ARFlag.svg';
import BRFlag from '../../assets/img/icons/BRFlag.svg';
import languageDropdownStyles from './LanguageDropdown.module.css';

export function LanguageDropdown(props) {
  const { t, i18n } = useTranslation();
  return (
    <Dropdown onSelectionChange={(lng) => i18n.changeLanguage(lng)} selected={i18n.language}>
      <Dropdown.Item value={'en'}>
        <Icon img={USFlag} label={t('languages:english')} classes={languageDropdownStyles['language-dropdown__icon']} />
      </Dropdown.Item>
      <Dropdown.Item value={'es'}>
        <Icon img={ARFlag} label={t('languages:spanish')} classes={languageDropdownStyles['language-dropdown__icon']} />
      </Dropdown.Item>
      <Dropdown.Item value={'pr'}>
        <Icon img={BRFlag} label={t('languages:portugese')} classes={languageDropdownStyles['language-dropdown__icon']} />
      </Dropdown.Item>
    </Dropdown>
  );
}
