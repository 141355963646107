import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import reachOutStyles from './ReachOut.module.css';

export function ReachOut() {
  const { t } = useTranslation();
  return (
    <button className={reachOutStyles.button}>
      <Link smooth to='#contact'>{t('buttons:reach-out')}</Link>
    </button>
  );
}
