import React from 'react';
import tabContentStyles from './TabContent.module.css';
import { TabContentHeader } from './TabContentHeader/TabContentHeader';

export function TabContent(props) {
  return (
    <div className={tabContentStyles['tab-content']}>
      <div className={tabContentStyles['tab-content__content']}>
        <TabContentHeader icon={props.icon} label={props.title} />
        <p>{props.text}</p>
      </div>
      {props.img ? (
        <div
          className={[
            tabContentStyles['tab-content__image'],
            props.colorStyle ? tabContentStyles[`tab-content__image--${props.colorStyle}`] : '',
          ].join(' ')}
        >
          <img src={props.img} alt='' />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
