import React from 'react';
import tabItemHeaderStyles from './TabItemHeader.module.css';
import classnames from 'classnames';

export function TabItemHeader(props) {
  return (
    <div
      className={classnames(tabItemHeaderStyles['tab-item-header'], {
        [tabItemHeaderStyles['tab-item-header--selected']]: props.isSelected,
      })}
      onClick={() => props.onSelect()}
    >
      {props.children(props.isSelected)}
    </div>
  );
}
