import React from 'react';
import background from '../../../assets/img/welcome-background.webp';
import { Icon } from '../../Icon/Icon';
import { Links } from '../../Links/Links';
import { NavBar } from '../../NavBar/NavBar';
import welcomeStyles from './Welcome.module.css';
import TickIcon from '../../../assets/img/icons/tick.svg';
import { useTranslation } from 'react-i18next';

export function Welcome() {
  const { t } = useTranslation();
  return (
    <div style={{ backgroundImage: `url(${background})` }} className={welcomeStyles.welcome} id="home">
      <NavBar />
      <div className={welcomeStyles.welcome__content}>
        <h1 dangerouslySetInnerHTML={{ __html: t('section:welcome:title') }}></h1>
        <Icon.InlineGroup>
          <Icon img={TickIcon} label={t('icon:precision')} classes={welcomeStyles.welcome__icon} />
          <Icon img={TickIcon} label={t('icon:tracking')} classes={welcomeStyles.welcome__icon} />
          <Icon img={TickIcon} label={t('icon:state')} classes={welcomeStyles.welcome__icon} />
        </Icon.InlineGroup>
        <div className={welcomeStyles.welcome__cta}>
          <Links.GetPricing></Links.GetPricing>
        </div>
      </div>
    </div>
  );
}
