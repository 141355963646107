import React from 'react';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { Tabs } from '../../Tabs/Tabs';
import background from '../../../assets/img/characteristics-background.png';

import mapIconSVG from '../../../assets/img/icons/map.svg';
import mapIconSelectedSVG from '../../../assets/img/icons/map.selected.svg';
import alertIconSVG from '../../../assets/img/icons/bell.svg';
import alertIconSelectedSVG from '../../../assets/img/icons/bell.selected.svg';
import clockIconSVG from '../../../assets/img/icons/clock.svg';
import clockIconSelectedSVG from '../../../assets/img/icons/clock.selected.svg';
import clockTurnIconSVG from '../../../assets/img/icons/clock-turn.svg';
import clockTurnIconSelectedSVG from '../../../assets/img/icons/clock-turn.selected.svg';
import peopleIconSVG from '../../../assets/img/icons/people.svg';
import peopleIconSelectedSVG from '../../../assets/img/icons/people.selected.svg';
import { useTranslation } from 'react-i18next';
import { SelectableItem } from '../../SelectableItem/SelectableItem';
import { TabContent } from '../../TabContent/TabContent';

import firstImage from '../../../assets/img/characteristics/1.png';
import secondImage from '../../../assets/img/characteristics/2.png';
import thirdImage from '../../../assets/img/characteristics/3.png';
import fourthImage from '../../../assets/img/characteristics/4.png';
import fifthImage from '../../../assets/img/characteristics/5.png';

export function Fleet(props) {
  const [t] = useTranslation();
  return (
    <section id="flota" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundColor: '#EEF4F6' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <SectionHeader title='section:fleet:title'></SectionHeader>
        <div style={{ padding: '5rem 0' }}>
          <Tabs>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={mapIconSelectedSVG}
                    icon={mapIconSVG}
                    label={t('section:fleet:tabs:1:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={mapIconSelectedSVG}
                  title={t('section:fleet:tabs:1:title')}
                  text={t('section:fleet:tabs:1:content')}
                  img={firstImage}
                  colorStyle='green'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={clockIconSelectedSVG}
                    icon={clockIconSVG}
                    label={t('section:fleet:tabs:2:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={clockIconSelectedSVG}
                  title={t('section:fleet:tabs:2:title')}
                  text={t('section:fleet:tabs:2:content')}
                  img={secondImage}
                  colorStyle='green'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={peopleIconSelectedSVG}
                    icon={peopleIconSVG}
                    label={t('section:fleet:tabs:3:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={peopleIconSelectedSVG}
                  title={t('section:fleet:tabs:3:title')}
                  text={t('section:fleet:tabs:3:content')}
                  img={thirdImage}
                  colorStyle='green'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={alertIconSelectedSVG}
                    icon={alertIconSVG}
                    label={t('section:fleet:tabs:4:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={alertIconSelectedSVG}
                  title={t('section:fleet:tabs:4:title')}
                  text={t('section:fleet:tabs:4:content')}
                  img={fourthImage}
                  colorStyle='green'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={clockTurnIconSelectedSVG}
                    icon={clockTurnIconSVG}
                    label={t('section:fleet:tabs:5:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={clockTurnIconSelectedSVG}
                  title={t('section:fleet:tabs:5:title')}
                  text={t('section:fleet:tabs:5:content')}
                  img={fifthImage}
                  colorStyle='green'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
          </Tabs>
        </div>
      </div>
    </section>
  );
}
