import React from 'react';
import logo from '../../assets/img/logo/lalliance.svg'
import logoWhite from '../../assets/img/logo/lalliance-white.svg'
import logoStyles from './Logo.module.css';

const LOGO_STYLE_MAPPER = {
  default: logo,
  black: logo,
  white: logoWhite,
};

export function Logo(props) {
  const logo = LOGO_STYLE_MAPPER[props.logoStyle ?? 'default'];

  return (
    <div className={logoStyles.logo}>
      <img src={logo} alt="Logo de L'alliance" />
    </div>
  );
}
