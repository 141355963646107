import React from 'react';
import 'react-dom';
import 'react-router-dom';
import './App.css';
import { Sections } from './components/Sections/Sections';
import { Topbar } from './components/Topbar/Topbar';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/locales/en/page.json';
import es from './assets/locales/es/page.json';
import pr from './assets/locales/pr/page.json';
import { NavBar } from './components/NavBar/NavBar';
import { Footer } from './components/Footer/Footer';

const resources = {
  en,
  es,
  pr
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'es',
    fallbackLng: 'es',
    keySeparator: true, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

function App() {
  return (
    <>
      <Topbar />
      <NavBar fixed showFrom={600} />
      <Sections.Welcome />
      {/* <Sections.RentACar /> */}
      <Sections.Renting />
      <Sections.Fleet />
      <Sections.Company />
      <Sections.Contact />
      <Footer />
    </>
  );
}

export default App;
