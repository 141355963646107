import React from 'react';
import dropdownStyles from './Dropdown.module.css';
import { Item } from './Item/Item';
import ChevronIconSVG from '../../assets/img/icons/chevron-down.svg';
import { Icon } from '../Icon/Icon';

export class Dropdown extends React.Component {
  static Item = Item;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: this.props.selected,
    };
  }

  onItemSelection() {
    return (value) => {
      this.props.onSelectionChange(value);
      this.setState({ selected: value });
    };
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  render() {
    let selectedItem;
    const items = React.Children.map(this.props.children, (child) => {
      if (child.props.value === this.props.selected) {
        selectedItem = React.cloneElement(child, { selected: true });
      }
      return React.cloneElement(child, { onClickHandler: this.onItemSelection(), selected: false });
    });

    return (
      <div className={dropdownStyles.dropdown} onClick={() => this.handleClick()}>
        <div className={dropdownStyles['dropdown__selected-item']}>
          {selectedItem}
          <Icon img={ChevronIconSVG} classes={dropdownStyles['dropdown__chevron']} size={'10px'} />
        </div>
        <div
          className={[
            dropdownStyles.dropdown__items,
            this.state.open ? dropdownStyles['dropdown__items--open'] : '',
          ].join(' ')}
        >
          {items}
        </div>
      </div>
    );
  }
}
