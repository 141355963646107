import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { Grid, TextField } from '@material-ui/core';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Links } from '../../Links/Links';
import { useContact } from '@wb/react-hooks';

export function Contact(props) {
  const [t] = useTranslation();
  const [form, setForm] = useReducer((state, newValues) => ({ ...state, ...newValues }), {});
  const { create } = useContact('65ee7091b0324631760ae53c');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const submitForm = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsLoading(true);
    create(form)
      .then(() => {
        setIsSuccess(true);
        setForm({ name: '', email: '', phone: '', comment: '' });
      })
      .catch(() => setIsSuccess(false))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isSuccess !== null) {
      setTimeout(() => setIsSuccess(null), 10000);
    }
  }, [isSuccess]);

  return (
    <section id="contact" style={{ backgroundColor: 'white' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <SectionHeader title='section:contact:title'></SectionHeader>
        <div style={{ padding: '5rem 0' }}>
          <form onSubmit={submitForm}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  placeholder='Nombre y Apellido'
                  label='Nombre y Apellido'
                  variant='outlined'
                  fullWidth
                  required
                  value={form.name}
                  onChange={(v) => setForm({ name: v.currentTarget.value })}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type='email'
                  placeholder='Email'
                  label='Correo electrónico'
                  variant='outlined'
                  fullWidth
                  required
                  value={form.email}
                  onChange={(v) => setForm({ email: v.currentTarget.value })}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type='phone'
                  placeholder='Teléfono'
                  label='Teléfono'
                  variant='outlined'
                  fullWidth
                  value={form.phone}
                  onChange={(v) => setForm({ phone: v.currentTarget.value })}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Mensaje'
                  multiline
                  rows={4}
                  placeholder='Tu pregunta'
                  variant='outlined'
                  fullWidth
                  required
                  value={form.comment}
                  onChange={(v) => setForm({ comment: v.currentTarget.value })}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <Links.SubmitForm disabled={isLoading} />
              </Grid>
              {isSuccess ? (
                <Grid item xs={12}>
                  <Alert severity='success'>Email enviado con exito!</Alert>
                </Grid>
              ) : isSuccess === false ? (
                <Grid item xs={12}>
                  <Alert severity='error'>
                    Ocurrio un error al enviar el email, por favor intentelo de nuevo mas tarde!
                  </Alert>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </form>
        </div>
      </div>
    </section>
  );
}
