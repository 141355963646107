import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useReducer } from "react";
import { useDocuments } from "@wb/react-hooks";
import { useTranslation } from "react-i18next";


const variantTogglerDispatcher = (
  state,
  { id: variantId, price }
) => {
  if (state.enabled.includes(variantId)) {
    return {
      enabled: [...state.enabled.filter((v) => v !== variantId)],
      total: state.total - price
    }
  } else {
    return {
      enabled: [...state.enabled, variantId],
      total: state.total + price
    }
  }
};

export const Product = ({
  rentLengthInDays,
  product,
  onSelectProduct,
}) => {
  const [t] = useTranslation();
  const { getPublicUrl } = useDocuments();
  const [variantsSelected, toggleVariant] = useReducer(
    variantTogglerDispatcher,
    { enabled: [], total: 0 }
  );
  const { variants } = product;
  const productHasDiscount = product.amountBeforeDiscounts !== product.amount;
  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {t('booking:product:daySummary', { days: rentLengthInDays })}
        </Typography>
        <Stack direction={'row'} sx={{ gap: '1rem', alignItems: 'flex-start' }}>
          <img src={getPublicUrl(product.gallery[0])} alt={product.name.es} style={{ display: 'block', width: '150px', height: '200px', objectFit: 'contain' }}/>
          <Stack sx={{ display: 'grid', gridTemplate: "1fr / .7fr .3fr", flexGrow: 1 }}>
            <Stack sx={{ flexGrow: 1 }}>
              <Typography variant="h5" component="div">
                {product.name.es}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {product.description?.es}
              </Typography>
            </Stack>
            <Stack direction={"column"} sx={{ alignItems: 'flex-end' }}>
              <Typography
                style={{
                  textDecoration: productHasDiscount ? "line-through" : "none",
                  opacity: productHasDiscount ? 0.75 : 1,
                }}
              >
                ${product.amountBeforeDiscounts}
              </Typography>
              {productHasDiscount && (
                <Typography style={{ color: "green" }}>
                  ${product.amount}
                </Typography>
              )}
              {variantsSelected.enabled.length > 0 ? (
                <Typography>{t('booking:product:variant:amount', { total: variantsSelected.total })}</Typography>
              ) : (
                <></>
              )}
            </Stack>
            <Stack sx={{ gridColumnStart: '1', gridColumnEnd: '3' }}>
              {variants.length > 0 && (
                <>
                  <Typography>
                    {t('booking:product:variant:count', { count: variants.length })}
                  </Typography>
                  {variants.map((variant) => (
                    <Accordion sx={{ width: "100%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{variant.name.es}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack
                          direction="row"
                          sx={{ justifyContent: "space-between", width: "100%" }}
                        >
                          <Stack direction={"column"}>
                            {variant.attributes.length > 0 && (
                              <ul>
                                {variant.attributes.map((a) => (
                                  <li>{a.name.es}</li>
                                ))}
                              </ul>
                            )}
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                      <AccordionActions>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            toggleVariant({
                              id: variant._id,
                              price: variant.amountBeforeDiscounts,
                            })
                          } 
                          }
                        >
                          {variantsSelected.enabled.includes(variant._id)
                            ? t('booking:product:variant:remove')
                            : t('booking:product:variant:add', { total: variant.amountBeforeDiscounts })}
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  ))}
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            onSelectProduct({
              product: product._id,
              variants: variantsSelected.enabled.map(vId => ({ variant: vId })),
            })
          }
        >
          {t('booking:product:select')}
        </Button>
      </CardActions>
    </Card>
  );
};
