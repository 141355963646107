import React from 'react';
import { BrowserRouter, Routes as ReactRoutes, Route } from 'react-router-dom';
import App from './App';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useWBSuite } from '@wb/react-hooks';

const basename = process.env.REACT_APP_BASENAME ?? '/';

export const Routes = () => {
  useWBSuite({ apiKey: process.env.REACT_APP_API_KEY });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter basename={basename}>
        <ReactRoutes>
          <Route path='/' element={<App />}></Route>
        </ReactRoutes>
      </BrowserRouter>
    </LocalizationProvider>
  );
};
