import React from 'react';
import tabsHeaderStyles from './TabsHeaderStyles.module.css';

export function TabsHeader(props) {
  return (
    <div className={tabsHeaderStyles['tabs-header']}>
      {props.children.map((child, idx) =>
        React.cloneElement(child, {
          key: `header--${idx}`,
          isSelected: props.selectedIdx === idx,
          onSelect: () => props.onSelectionChange(idx),
        })
      )}
    </div>
  );
}
