import { Company } from './Company/Company';
import { Welcome } from './Welcome/Welcome';
import { Fleet } from './Fleet/Fleet';
import { Renting } from './Renting/Renting';
import { Contact } from './Contact/Contact';
import { RentACar } from './RentACar/RentACar';

export class Sections {
  static Welcome = Welcome;
  static Company = Company;
  static Renting = Renting;
  static Fleet = Fleet;
  static Contact = Contact;
  static RentACar = RentACar;
}
