import React from 'react';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { Tabs } from '../../Tabs/Tabs';

import mapIconSVG from '../../../assets/img/icons/map.svg';
import mapIconSelectedSVG from '../../../assets/img/icons/map.selected.svg';
import alertIconSVG from '../../../assets/img/icons/bell.svg';
import alertIconSelectedSVG from '../../../assets/img/icons/bell.selected.svg';
import clockIconSVG from '../../../assets/img/icons/clock.svg';
import clockIconSelectedSVG from '../../../assets/img/icons/clock.selected.svg';
import clockTurnIconSVG from '../../../assets/img/icons/clock-turn.svg';
import clockTurnIconSelectedSVG from '../../../assets/img/icons/clock-turn.selected.svg';
import peopleIconSVG from '../../../assets/img/icons/people.svg';
import peopleIconSelectedSVG from '../../../assets/img/icons/people.selected.svg';
import { useTranslation } from 'react-i18next';
import { SelectableItem } from '../../SelectableItem/SelectableItem';
import { TabContent } from '../../TabContent/TabContent';

import mapImage from '../../../assets/img/producto/map.png';
import alertImage from '../../../assets/img/producto/alert.png';
import employeeImage from '../../../assets/img/producto/employee.png';
import historyImage from '../../../assets/img/producto/history.png';
import timeMachineImage from '../../../assets/img/producto/timemachine.png';

export function Renting(props) {
  const [t] = useTranslation();
  return (
    <section style={{ backgroundColor: '#F2F8FD' }} id="renting">
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <SectionHeader title='section:renting:title' subtitle='section:renting:subtitle'></SectionHeader>
        <div style={{ padding: '5rem 0' }}>
          <Tabs>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={mapIconSelectedSVG}
                    icon={mapIconSVG}
                    label={t('section:renting:tabs:assistance:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={mapIconSelectedSVG}
                  title={t('section:renting:tabs:assistance:title')}
                  text={t('section:renting:tabs:assistance:content')}
                  img={mapImage}
                  colorStyle='blue'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={clockIconSelectedSVG}
                    icon={clockIconSVG}
                    label={t('section:renting:tabs:own-shops:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={clockIconSelectedSVG}
                  title={t('section:renting:tabs:own-shops:title')}
                  text={t('section:renting:tabs:own-shops:content')}
                  img={historyImage}
                  colorStyle='blue'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={peopleIconSelectedSVG}
                    icon={peopleIconSVG}
                    label={t('section:renting:tabs:fleet-renewal:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={peopleIconSelectedSVG}
                  title={t('section:renting:tabs:fleet-renewal:title')}
                  text={t('section:renting:tabs:fleet-renewal:content')}
                  img={employeeImage}
                  colorStyle='blue'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={alertIconSelectedSVG}
                    icon={alertIconSVG}
                    label={t('section:renting:tabs:gps-tracking:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={alertIconSelectedSVG}
                  title={t('section:renting:tabs:gps-tracking:title')}
                  text={t('section:renting:tabs:gps-tracking:content')}
                  img={alertImage}
                  colorStyle='blue'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
            <Tabs.TabItem>
              <Tabs.TabItemHeader>
                {(selected) => (
                  <SelectableItem
                    selected={selected}
                    onSelectedIcon={clockTurnIconSelectedSVG}
                    icon={clockTurnIconSVG}
                    label={t('section:renting:tabs:cost-reduction:title')}
                  />
                )}
              </Tabs.TabItemHeader>
              <Tabs.TabItemContent>
                <TabContent
                  icon={clockTurnIconSelectedSVG}
                  title={t('section:renting:tabs:cost-reduction:title')}
                  text={t('section:renting:tabs:cost-reduction:content')}
                  img={timeMachineImage}
                  colorStyle='blue'
                />
              </Tabs.TabItemContent>
            </Tabs.TabItem>
          </Tabs>
        </div>
      </div>
    </section>
  );
}
